<template>
	<div
		:class="{
			'character': true,
			[ `is-${ id || type }` ]: true,
			'has-wiggle': wiggle,
		}"
		:style="{
			'--aspect': height / width,
		}"
	>
		<AppImage
			:srcset="[
				require(`@/assets/img/characters/${ path }.webp`),
				require(`@/assets/img/characters/${ path }.png`),
			]"
			:width="width"
			:height="height"
		/>
		<div
			ref="animation"
			class="character__animation"
		/>
		<svg
			class="character__legs"
			viewBox="0 0 100 90"
		>
			<path
				class="character__leg--left"
				d="M27.7,5.6C37,8,31.4,58.9,27.4,84"
			/>
			<path
				class="character__leg--right"
				d="M72.6,5.7c-9.2,2.4-4.1,53.3-0.3,78.5"
			/>
		</svg>
		<slot />
	</div>
</template>

<script>
import lottie from 'lottie-web';
import * as characterAnimations from '../animations';

// Canvas: 400x516
// Capacitor: 316x445 @ 32px
// Ceramic: 400x413 @ 32px
// Block: 292x516 @ 0px
const coloredAvatars = [ 'capacitor', 'ceramic', 'block', 'answer' ];

const sizes = {
	capacitor: {
		width: 200,
		height: 258,
	},
	ceramic: {
		width: 200,
		height: 258,
	},
	block: {
		width: 200,
		height: 258,
	},
	answer: {
		width: 340,
		height: 438,
	},
	lights: {
		width: 213,
		height: 535,
	},
	cloud: {
		width: 403,
		height: 281,
	},
};

const node2vnode = new WeakMap();
const animationObserver = new IntersectionObserver( ( entries ) => {
	entries.forEach( ( entry ) => {
		const { target } = entry;
		const vnode = node2vnode.get( target );
		if ( vnode ) {
			vnode.isVisible = entry.isIntersecting;
		}
	} );
} );

export default {
	props: {
		id: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: 'capacitor',
		},
		color: {
			type: String,
			default: 'magenta',
		},
		align: {
			type: String,
			default: 'left',
		},
		animation: {
			type: String,
			default: 'interaction',
		},
		clip: {
			type: String,
			default: null,
		},
		wiggle: Boolean,
	},
	data() {
		return {
			isVisible: false,
		};
	},
	computed: {
		path() {
			if ( this.id ) {
				return this.id;
			}

			let path = this.type;
			if ( coloredAvatars.includes( this.type ) ) {
				path += '-' + this.color;
			}

			return path;
		},
		width() {
			return sizes[ this.id || this.type ].width;
		},
		height() {
			return sizes[ this.id || this.type ].height;
		},
		animationSet() {
			return characterAnimations[ this.animation ];
		},
		animationClip() {
			return this.clip && this.animationSet.clips[ this.clip ];
		},
	},
	watch: {
		isVisible( newStatus ) {
			if ( newStatus ) {
				this.play();
			} else {
				this.lottieAnimation.stop();
			}
		},
		animationSet( newSet ) {
			this.lottieAnimation.destroy();
			this.setupAnimation( newSet );
		},
		animationClip( newClip, oldClip ) {
			if ( newClip ) {
				let force = true;
				if ( oldClip && oldClip.out ) {
					// transition out of previous animation
					this.lottieAnimation.playSegments( oldClip.out, true );
					force = false;
				}

				if ( newClip.in ) {
					// transition into and loop next animation
					this.lottieAnimation.playSegments( newClip.in, force );
					force = ! force;
				}

				this.lottieAnimation.playSegments( newClip.loop, force );
			} else {
				this.lottieAnimation.playSegments( [ 0, 1 ], true );
			}
		},
	},
	mounted() {
		this.setupAnimation( this.animationSet );

		node2vnode.set( this.$refs.animation, this );
		animationObserver.observe( this.$refs.animation );
	},
	destroyed() {
		const { lottieAnimation } = this;

		lottieAnimation.stop();
		setTimeout( () => lottieAnimation.destroy(), 2000 );
	},
	methods: {
		setupAnimation( animation ) {
			this.lottieAnimation = lottie.loadAnimation( {
				container: this.$refs.animation,
				renderer: 'svg',
				loop: true,
				autoplay: false,
				animationData: animation.data,
			} );
		},
		play() {
			if ( this.animationSet.clips ) {
				if ( this.animationClip ) {
					this.lottieAnimation.playSegments( this.animationClip.loop, true );
				} else {
					this.lottieAnimation.playSegments( [ 0, 1 ], true );
				}
			} else {
				this.lottieAnimation.play();
			}
		},
	},
};
</script>

<style lang="scss">
@keyframes legWiggle {
	from {
		transform: rotate(-5deg);
	}
	to {
		transform: rotate(5deg);
	}
}

.character {
	position: relative;
	z-index: 0;
	margin: auto;

	&::before {
		content: '';
		display: block;
		padding-bottom: calc(var(--aspect, 1) * 100%);
	}

	.image {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	&__animation {
		position: absolute;
		z-index: 2;
		top: -10%;
		left: -15%;
		width: 130%;
		pointer-events: none;

		svg {
			display: block;
			width: 100%;
			height: auto;
		}

		.is-answer & {
			top: -5%;
			left: -10%;
			width: 120%;
		}

		.is-lights & {
			top: -9%;
			left: -50%;
			width: 200%;
		}

		.is-cloud & {
			top: -1%;
			left: -1%;
			width: 100%;
		}
	}

	&__legs {
		position: absolute;
		z-index: 0;
		top: 70%;
		left: 25%;
		width: 50%;
		pointer-events: none;

		path {
			fill: none;
			stroke: #292C2E;
			stroke-width: 10;
			stroke-linecap: round;
			stroke-linejoin: round;

			.has-wiggle & {
				animation: legWiggle .5s $easeInOutSine alternate infinite;
			}
		}

		.is-block &,
		.is-lights &,
		.is-cloud & {
			display: none;
		}
	}
		&__leg--left {
			transform-origin: 30% 0;
			animation-delay: -.2s;
		}
		&__leg--right {
			transform-origin: 70% 0;
		}
}
</style>
