var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {
		'character': true
	}, _obj[ ("is-" + (_vm.id || _vm.type)) ] = true, _obj['has-wiggle'] =  _vm.wiggle, _obj ),style:({
		'--aspect': _vm.height / _vm.width,
	})},[_c('AppImage',{attrs:{"srcset":[
			require(("@/assets/img/characters/" + _vm.path + ".webp")),
			require(("@/assets/img/characters/" + _vm.path + ".png")) ],"width":_vm.width,"height":_vm.height}}),_c('div',{ref:"animation",staticClass:"character__animation"}),_c('svg',{staticClass:"character__legs",attrs:{"viewBox":"0 0 100 90"}},[_c('path',{staticClass:"character__leg--left",attrs:{"d":"M27.7,5.6C37,8,31.4,58.9,27.4,84"}}),_c('path',{staticClass:"character__leg--right",attrs:{"d":"M72.6,5.7c-9.2,2.4-4.1,53.3-0.3,78.5"}})]),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }