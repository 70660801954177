import interactionsData from './interactions.json';

export const interaction = {
	data: interactionsData,
	clips: {
		picked: {
			in: [ 0, 12 ],
			loop: [ 12, 35 ],
			out: [ 35, 40 ],
		},
		floss: {
			in: [ 40, 50 ],
			loop: [ 50, 80 ],
			out: [ 80, 90 ],
		},
		notpicked: {
			in: [ 91, 98 ],
			loop: [ 98, 126 ],
			out: [ 126, 129 ],
		},
		pickme: {
			in: [ 131, 141 ],
			loop: [ 141, 178 ],
		},
		eyes: {
			in: [ 179, 189 ],
			loop: [ 189, 227 ],
		},
	},
};

import reactionsData from './reactions.json';

export const reaction = {
	data: reactionsData,
	clips: {
		default: {
			in: [ 0, 12 ],
			loop: [ 12, 93 ],
			out: [ 93, 99 ],
		},
		surprise: {
			in: [ 99, 111 ],
			loop: [ 111, 149 ],
			out: [ 149, 158 ],
		},
		happy: {
			in: [ 159, 171 ],
			loop: [ 171, 252 ],
			out: [ 252, 258 ],
		},
		woah: {
			in: [ 258, 317 ],
			loop: [ 317, 337 ],
			out: [ 330, 352 ],
		},
		love: {
			in: [ 353, 364 ],
			loop: [ 387, 410 ],
			out: [ 410, 417 ],
		},
		reward: {
			in: [ 418, 429 ],
			loop: [ 452, 475 ],
			out: [ 475, 481 ],
		},
		ohh: {
			in: [ 483, 496 ],
			loop: [ 496, 516 ],
		},
		hmm: {
			in: [ 517, 530 ],
			loop: [ 530, 567 ],
		},
	},
};

import cloudData from './cloud.json';

export const cloud = {
	data: cloudData,
};

import andonData from './andon.json';

export const andon = {
	data: andonData,
};
